<template>
  <div class="not_found">
    <div class="content_box">
      <div class="img_box">
        <img src="../../assets/not_found.png" alt="" />
      </div>
      <div class="font_box">
        <div class="not_found_txt">NOT FOUND！</div>
        <div class="msg">页面一不小心走丢了…</div>
        <div class="desc">请检查您进入的URL地址是否正确，</div>
        <div class="desc2">
          页面将在 <span>{{ count }}</span> 秒钟后返回
        </div>

        <span class="btn">
          <span @click="goBack">立即返回</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 倒计时结束时 返回上一个路由
      // 点击按扭的时候 也要结束定时器
      timer: null,
      // 计时多久返回
      count: 5
    }
  },
  methods: {
    // 开启定时器
    setTimer() {
      this.timer = setInterval(() => {
        this.count--
        if (this.count <= 0) {
          clearInterval(this.timer)
          this.timer = null
          this.$router.go(-1)
        }
      }, 1000)
    },

    // 用户点击定时器
    goBack() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.$router.go(-1)
    }
  },
  created() {
    this.setTimer()
  }
}
</script>

<style lang='scss' scoped>
.not_found {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_box {
  width: 1110px;
  display: flex;

  .img_box {
    flex: 1;
    height: 305px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .font_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .not_found_txt {
      font-size: 28px;
      font-weight: 600;
      color: #55aafe;
    }

    .msg {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      margin-top: 24px;
    }

    .desc {
      color: #999;
      font-size: 14px;
      margin-top: 16px;
    }

    .desc2 {
      color: #999;
      font-size: 14px;
      margin-top: 6px;
      margin-bottom: 32px;

      span {
        color: #55aafe;
      }
    }
  }

  .btn {
    span {
      padding: 9px 15px;
      background-color: #55aafe;
      border-radius: 20px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      transition: all 0.14s;

      &:hover {
        background: rgba(85, 170, 254, 0.8);
      }
    }
  }
}
</style>
